import firebase from "firebase"

import type { Client } from "@/types/client"

import { FirebaseService } from "./firebase.service"

export default class ClientService extends FirebaseService {
  addClient(client: Client) {
    return firebase
      .database()
      .ref("clients")
      .push(client)
      .then(({ key }) => {
        return this.getClientByID(key)
      })
  }

  getClientByID(id: string): Promise<Client | null> {
    return firebase
      .database()
      .ref(`clients/${id}`)
      .once("value")
      .then(snapshot => {
        const value: Client | null = snapshot.val()
        return value ? this.normalizeObjectLikeSnapshot(value, id) : value
      })
  }

  updateClient(client: Client) {
    const newPayload = { ...client }

    // TODO(Andrew): get rid of it
    delete newPayload.id
    // @ts-ignore
    delete newPayload.theKey
    // @ts-ignore
    delete newPayload.games
    // @ts-ignore
    delete newPayload.gameIDs
    return firebase
      .database()
      .ref(`clients/${client.id}`)
      .update(newPayload)
      .then(() => this.getClientByID(client.id))
  }

  deleteClient(id: string) {
    return firebase.database().ref(`clients/${id}`).remove()
  }

  getClients(orgID: string): Promise<Client[]> {
    const ref = firebase
      .database()
      .ref("clients")
      .orderByChild("orgID")
      .equalTo(orgID)

    return ref.once("value").then(snapshot => {
      const value: Record<string, Client> | null = snapshot.val()

      if (value) {
        return this.normalizeSnapshotToArray(value)
      } else {
        return []
      }
    })
  }
}
